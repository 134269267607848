







import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Hero extends Vue {
  @Prop({ default: "" }) content?: string;
  @Prop({ default: { formats: { large: { url: "/some-url" } } } })
  image?: any;
}
